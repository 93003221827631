import React from 'react';
import Layout from '../components/layout';

const Thankyou = () => {

  return (
    <Layout>
      <main className="wrapper main-data-container" data-datocms-noindex>
        <h1>Thank you!</h1>
        <p>Thank you for entering our competition - good luck!</p>
        
        <p>
          Why not head straight to Bonmarché to use your <strong>AGECOMP24</strong> coupon code?
        </p>
        <p>
          <a 
            href="https://click.linksynergy.com/deeplink?id=0nvzq18Kv1M&mid=50542&murl=https%3A%2F%2Fwww.bonmarche.co.uk%2F" 
            className="text-blue-500 underline"
          >
            Visit Bonmarché now!
          </a>
        </p>
        
        <p>
          Alternatively, return to Age Times and <a href="/fashion-beauty/how-to-look-stylish/bonmarche" className="text-blue-500 underline">read all about Bonmarché’s latest collection</a>.
        </p>
      </main>
    </Layout>
  );
};

export default Thankyou;
